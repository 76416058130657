import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { ALLOWED_ACCOUNTS_KEY, RaiUser } from '../components/auth/raiUser';

const lsKey = 'lastAccount';

export function getAccount(accounts: string[]) {
  const lastAccount =
    typeof localStorage !== 'undefined' && localStorage.getItem(lsKey);

  return accounts.find(accId => accId === lastAccount) || accounts[0];
}

export default function useAccount() {
  const { user } = useAuth0<RaiUser>();
  const router = useRouter();
  const queryAccountId = router.query.accountId as string | undefined;

  useEffect(() => {
    if (queryAccountId) {
      localStorage.setItem(lsKey, queryAccountId);
    }
  }, [queryAccountId]);

  if (user && router.isReady) {
    const accounts = user[ALLOWED_ACCOUNTS_KEY];

    const accountId =
      accounts.find(accId => accId === queryAccountId) || getAccount(accounts);

    return {
      accounts,
      accountId,
    };
  }

  throw new Error(`Couldn't get accountId.`);
}
