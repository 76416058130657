import { useAuth0 } from '@auth0/auth0-react';

import { Button } from '@relationalai/ui';

import squareLogo from '../../../public/images/full-relationalai-logo.png';

type AuthPanelProps = {
  children: JSX.Element | JSX.Element[];
};

export function AuthPanel({ children }: AuthPanelProps) {
  return (
    <div className='min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-100'>
      <div className='mt-8 sm:mx-auto sm:max-w-lg'>
        <div className='bg-white py-14 px-4 shadow sm:rounded-lg sm:px-12'>
          <img
            className='h-auto m-auto'
            src={squareLogo.src}
            height={52}
            width={382}
            alt='RelationalAI'
          />
          <div className='w-full mt-8'>{children}</div>
        </div>
      </div>
    </div>
  );
}

export function AuthLoadingPanel() {
  return (
    <AuthPanel>
      <div>
        <h2 className='text-xl font-medium'>Connecting...</h2>
        <p className='py-0 text-gray-600'>(This may take a minute)</p>
      </div>
    </AuthPanel>
  );
}

type AuthErrorPanelProps = {
  error: { message: string };
};

export function AuthErrorPanel({ error }: AuthErrorPanelProps) {
  const { logout } = useAuth0();

  if (error?.message.toLowerCase().includes('webtask exceeded')) {
    // eslint-disable-next-line no-console
    console.warn(error);
    error = new Error('Authentication failed. Please try again.');
  }

  const returnTo =
    typeof window !== 'undefined' ? window.location.origin : undefined;

  return (
    <AuthPanel>
      <div className='text-red-500'>{error.message}</div>
      <div className='my-2 text-gray-800'>
        If this continues please contact{' '}
        <a className='text-red-orange-700' href='mailto:support@relational.ai'>
          support@relational.ai
        </a>
      </div>
      <div className='mt-4 flex justify-center'>
        <Button
          size='xl'
          data-testid='try-again-button'
          // Calling logout to workaround this
          // https://relationalai.atlassian.net/browse/RAI-9811
          onClick={() => logout({ returnTo })}
        >
          Try Again
        </Button>
      </div>
    </AuthPanel>
  );
}
