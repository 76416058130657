import { useAuth0 } from '@auth0/auth0-react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AuthPanel } from '../components/auth/AuthPanel';
import { ALLOWED_ACCOUNTS_KEY, RaiUser } from '../components/auth/raiUser';
import { getAccount } from '../hooks/useAccount';

function AccountRedirect() {
  const router = useRouter();
  const { user, isLoading, isAuthenticated } = useAuth0<RaiUser>();

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        router.replace('/login');
      } else if (
        user &&
        router.isReady &&
        !(router.query.code && router.query.state)
      ) {
        // don't redirect if there is a code and state query parameter as that means
        // we are still parsing the response from Auth0
        const accountId = getAccount(user[ALLOWED_ACCOUNTS_KEY]);

        router.replace(`/${accountId}`);
      }
    }
  });

  return (
    <AuthPanel>
      <Head>
        <title>Authorization | Relational AI Console</title>
      </Head>
      <div>
        <h2 className='text-md font-medium text-center'>Authorizing...</h2>
      </div>
    </AuthPanel>
  );
}

export default AccountRedirect;
